import React from 'react';
import './TourWithMe.css';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import ComingSoon from '../../assets/icons/coming soong.png';

function TourWithMe() {
  return (
    <div className='tourwithme'>
      <NavBar />
      <div className='comingsoon'>
        <img src={ComingSoon} alt='comingsoon'/>
      </div>
      <Footer />
    </div>
  );
}

export default TourWithMe;
