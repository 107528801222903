import React from 'react';
import './Footer.css';
import Facebook from '../../assets/icons/facebook.png';
import Instagram from '../../assets/icons/instagram.png';
import Spotify from '../../assets/icons/spotify.png';
import Youtube from '../../assets/icons/youtube.png';
import Anghami from '../../assets/icons/anghami.png';

function Footer() {
  return (
    <div className='footer'>
      <div className='content'>
        <div className='contact'>
          <p>CONTACT</p>
          <a
            className='hidden'
            href='mailto:info@laylakardan.com?subject=Hello&body=Hello%20again'
          >
            info@laylakardan.com
          </a>
        </div>
        <div className='social'>
          <a href='https://www.instagram.com/laylakardan'>
            <img className='instagram' src={Instagram} alt='instagram' />
          </a>
          <a href='https://www.facebook.com/laylakardan/'>
            <img className='facebook' src={Facebook} alt='facebook' />
          </a>
          <a href='https://open.spotify.com/artist/6anm3koLvedUfsUdmb0uAL?si=uwwT6yHxRK-hB1VXUYLgUg'>
            <img className='spotify' src={Spotify} alt='spotify' />
          </a>
          <a href='https://m.youtube.com/channel/UCQY-AO48itjGrYx_oOBLX7A'>
            <img className='youtube' src={Youtube} alt='youtube' />
          </a>
          <a href='https://play.anghami.com/LAYLA_KARDAN?bid=/yDsP9VbGL7'>
            <img className='anghami' src={Anghami} alt='anghami' />
          </a>
        </div>
        <div className='rights'>
          <p>By I Am Cynner@2020</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
