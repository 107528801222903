import React from 'react';
import './Merch.css';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import ComingSoon from '../../assets/icons/coming soong.png';

function Merch() {
  return (
    <div className='merch'>
      <NavBar />
      <div className='comingsoon'>
        <img src={ComingSoon} alt='comingsoon' />
      </div>
      <Footer />
    </div>
  );
}

export default Merch;
