import React, { useState } from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';
import './Home.css';
import Video from '../../assets/Video/Layla Kardan.mp4';
import Speaker from '../../assets/icons/speaker.png';
import Mute from '../../assets/icons/mute.png';

function Home() {
  const [isMuted, setIsMuted] = useState(true);
  const tooglesound = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className='App'>
      <NavBar />
      <div className='main'>
        <video autoPlay loop muted={isMuted} id='video-background'>
          <source src={Video} type='video/mp4' />
        </video>
        <div className='button-container'>
          <button onClick={tooglesound}>
            {isMuted ? (
              <img src={Mute} alt='mute' />
            ) : (
              <img src={Speaker} alt='speaker' />
            )}
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
