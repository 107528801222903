import React from 'react';
import './App.css';
import Home from './Pages/Home/Home';
import TourWithMe from './Pages/TourWithMe/TourWithMe';
import Merch from './Pages/Merch/Merch';
import { Box } from '@mui/material';
import { Routes, Route } from 'react-router-dom';

const App = () => {
  return (
    <Box width='400px' sx={{ width: { xl: '1488px' } }}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/tourwithme' element={<TourWithMe />} />
        <Route path='/merch' element={<Merch />} />
      </Routes>
    </Box>
  );
};

export default App;
