import React, { useState } from 'react';
import logo from '../../assets/Logo/logo.png';
import './NavBar.css';
import { Link } from 'react-router-dom';

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='navbar'>
      <a href='/'>
        <img src={logo} alt='logo' />
      </a>
      <nav className={`navbar_menu ${isOpen ? 'navbar_menu_open' : ''}`}>
        <Link to='/'>HOME</Link>
        <Link to='/tourwithme'>TOUR WITH ME</Link>
        <Link to='/merch'>MERCH</Link>
      </nav>
      <button className='hamburger' onClick={() => setIsOpen(!isOpen)}>
        <span
          className={`hamburger_line ${isOpen ? 'hamburger_line_open' : ''}`}
        ></span>
        <span
          className={`hamburger_line ${isOpen ? 'hamburger_line_open' : ''}`}
        ></span>
        <span
          className={`hamburger_line ${isOpen ? 'hamburger_line_open' : ''}`}
        ></span>
      </button>
    </div>
  );
}

export default NavBar;
